<template>
  <v-container pa-0 class="text-left">
    <div class="text-center pb-2">
      <span :class="`display-1 font-weight-bold`" style="border-bottom: solid .5px">
        Pitch Practice
      </span>
    </div>
    <setup-exercise @start-exercise="goToPitchPractice"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name:       'PianoDetector',
  components: {
    SetupExercise: () => import('src/sections/SetupExercise')
  },

  destroyed() {
    if(this.getIsTransportStarted) {
      this.stopPlayback()
    }
  },

  computed: {
    ...mapGetters('transport', ['getIsTransportStarted', 'getTransportBPM']),
    ...mapGetters('context', ['getPitch'])
  },

  methods: {
    ...mapActions('transport', [
      'setBPM', 'pauseTransport', 'stopPlayback'
    ]),

    goToPitchPractice(query) {
      this.$router.push(`/exercise?${ query }`)
    }
  }
}
</script>
